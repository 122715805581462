import { render, staticRenderFns } from "./assistance.vue?vue&type=template&id=8dc1c9e6&scoped=true&"
import script from "./assistance.vue?vue&type=script&lang=js&"
export * from "./assistance.vue?vue&type=script&lang=js&"
import style0 from "./assistance.vue?vue&type=style&index=0&id=8dc1c9e6&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dc1c9e6",
  null
  
)

export default component.exports