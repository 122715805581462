<template>
  <div class="richmond">
    <m-header-richmond title="Night Market Coupon"></m-header-richmond>
    <div class="main">
      <div class="main-head">
        <img src="@/assets/richmond/RNM_2022-logo.png" alt="" />
      </div>
      <div class="main-head2">
         <div class="head-img">
            <div class="head_bg">
              <img src="../../../assets/richmond/Rectangle16.png" alt="" />
            </div>
            <div class="head_content">
              <p>778 *** 4584</p>
              <div style="display: flex">
                <div>Ask for help</div>
              </div>
            </div>
          </div>
      </div>
      <div class="coupon">
        <div class="flex-item">
          <div>Login to unlock</div>
        </div>
        <div class="box">
          <img src="../../../assets/richmond/Group37.png" alt="">
        </div>
      </div>
      <div class="bottom">
        I want coupons too？
      </div>
    </div>
  </div>
</template>

<script>
import MHeaderRichmond from "@/components/en/m-header-richmond.vue";
export default {
  name: "Index",
  components: {
    MHeaderRichmond,
  },
  data() {
    return {};
  },
  methods: {

  },
  created() {
  }
};
</script>

<style scoped lang="less">
.richmond {
  font-size: 16px;
  font-family: FuturaBT-BoldItalic;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .main {
    width: 100%;
    min-height: 100%;
    background: url("../../../assets/richmond/couponbg.png") #249FF2;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    .main-head {
      padding: 100px 0 0;
      img {
        display: block;
        margin: auto;
        width: 286px;
        height: 88px;
      }
    }
    .main-head2 {
      margin-top: 8px;
      padding-left: 30px;
      z-index: 3;
      .head-img {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        .head_bg {
          z-index: 3;
          width: 80px;
          height: 80px;
          background: #5e00ba;
          border: 0.5px solid #000000;
          border-radius: 50%;
          img {
            width: 80px;
            height: 80px;
            position: relative;
            top: -4px;
            left: -4px;
            border-radius: 50%;
          }
        }
        .head_content {
          padding: 10px;
          padding-left: 35px;
          width: 260px;
          height: 66px;
          position: relative;
          font-weight: 500;
          font-size: 18px;
          top: 13px;
          left: -30px;
          background-image: url("../../../assets/richmond/Vector.png");
          background-size: 100% 100%;
          font-weight: 600;
          z-index: 2;
          p {
            padding-left: 4px;
          }
          div {
            line-height: 24px;
          }
        }
      }
    }
    .coupon {
      z-index: 1;
      width: 347px;
      position: relative;
      top: -15px;
      margin: auto;
      height: 350px;
      background-image: url("../../../assets/richmond/Group24.png");
      background-size: 100% 100%;
        .flex-item {
          width: 220px;
          margin: auto;
          position: relative;
          top: 166px;
          height: 44px;
          background: #089bd6;
          border: 1px solid #030424;
          border-radius: 30px;
          div {
            position: relative;
            top: -2px;
            width: 100%;
            height: 42px;
            text-align: center;
            color: #fff;
            line-height: 41px;
            background: linear-gradient(
                180deg,
                rgba(255, 136, 41, 0) 20.83%,
                rgba(245, 127, 33, 0.9) 100%
              ),
              #f86547;
            border-radius: 30px;
          }
        }
        .box {
          position: absolute;
          top: 250px;
          width: 100%;
          img {
            display: block;
            margin: auto;
            width: 308px;
            height: 177px;
          }
        }
    }
    .bottom {
      position: relative;
      top: 80px;
      text-align: center;
      color: #fff;
      text-decoration: underline;
    }
  }
}
</style>